<template>
    <div class="navbar d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="sidebar-toogle" @click="toogleSiderBar">
        <img src="@/assets/icons/burgir-menu.svg" alt="Arrow Down">
      </div>

      <div class="logo">
        <img src='@/assets/icons/ihs-logo.svg' alt="IHS">
      </div>
    </div>
    <div class="d-flex align-items-center gap-2 ps-4 pe-5 py-1 rounded-pill" style="border: 1px solid #E0E0E0;"  v-if="Object.keys(shift).length">
      <img src="@/assets/icons/balance-icon.svg" alt="">
      <div class="d-flex flex-column color-blue-dark-50">
        <span class="font-roboto font-10">Saldo Fisik</span>
        <span class="font-nunito font-700 font-18">{{formatRupiah(shift.cash_balance, 'Rp.')}}</span>
      </div>
    </div>
    <div class="cashier-info dropdown align-items-center">
      <div>
        <img 
          class="avatar"
         :src="getActiveCashier.image? getActiveCashier.image : getActiveCashier.gender === 'male' ? require('@/assets/illustration/empty-profile-male.svg') : require('@/assets/illustration/empty-profile-female.svg')"
          alt="Avatar">
      </div>
      <div class="info">
        <span class="font-roboto font-16">{{getActiveCashier.name}}</span>
        <span class="font-roboto font-12">Penjaga Kasir</span>
      </div>
      <el-dropdown  trigger="click" @command="logout">
        <div 
          class="more-btn mt-2" 
          id="cashierDropdown" >
          <img src="@/assets/icons/arrow-down-black.svg" alt="Arrow Down">
        </div>    
        <el-dropdown-menu slot="dropdown" class="p-0 m-0 logout-dropdown">
          <el-dropdown-item :data-bs-toggle="shiftId ? 'modal' : ''" :data-bs-target="shiftId ? '#endShift' : ''" >Keluar</el-dropdown-item>
      
        </el-dropdown-menu>
      </el-dropdown>
     
      <!-- <div class="dropdown-menu" aria-labelledby="cashierDropdown">
        <a class="dropdown-item" :data-bs-toggle="shiftId ? 'modal' : ''" :data-bs-target="shiftId ? '#endShift' : ''" @click="logout()">Keluar</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'
import { mapState, mapMutations } from 'vuex'

export default {
  mixins: [IhsMixin],
  data(){
    return{
      cashierSession: ''
    }
  },
  computed: {
    ...mapState('home', ['sidebarVisible']),
    ...mapState('auth', ['cashier']),
    ...mapState('shift', ['shiftId', 'shift']),
    getSession(){
      return localStorage.getItem('access-token')
    },

    getActiveCashier(){
      return JSON.parse(localStorage.getItem('activeCashier'))
    }
  },

  methods: {
    ...mapMutations('home', ['setSidebarVisible']),

    toogleSiderBar(){
      this.setSidebarVisible(!this.sidebarVisible)
    },

    logout(){
      if(!this.shiftId) {
           localStorage.removeItem('access-token')
          localStorage.removeItem('activeCashier')
          this.cashierSession = ''
      } else {
         this.$message({
          showClose: false,
          message: 'Silahkan Akhiri Shift Anda Terlebih Dahulu Sebelum Keluar',
          type: 'warning',
        });
      }
    }
  },
  mounted() {
    if (localStorage.getItem('access-token')) {
      this.cashierSession = localStorage.getItem('access-token');
    }
  },
  watch: {
    cashierSession: function(){
      if(this.cashierSession === ''){
        this.$router.push({name: 'Login'})
        this.$message({
          showClose: true,
          message: 'Anda berhasil keluar',
          type: 'success',
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown-menu{
  .dropdown-item{
    cursor: pointer;
  }
}

</style>

<style lang="scss">
.logout-dropdown {
  width: 130px;
  top: 55px !important;
  .el-dropdown-menu__item {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .popper__arrow {
    display: none !important;
  }
} 
</style>